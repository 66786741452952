<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <template v-if="pipe == 3">
                    <div class="row ml-0 mt-3 mb-3 mr-0" v-show="users.length > 0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                        </div>
                        <div class="col-auto pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <div class="btn-group w-100 alphabetic" :class="{'mb-3': platform == 'mobile'}">
                                <button type="button" class="btn btn-info btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span><i class="fas fa-sort-alpha-up mr-2"></i>Filtrar</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item text-center" type="button" @click="filterAz('all')"><i class="fas fa-filter mr-2"></i>Todos</button>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item text-uppercase" type="button" v-for="(letter, index) in alphabetic" @click="filterAz(letter)" :key="index">
                                        <template v-if="filter_az_active == '' || filter_az_active != letter">
                                            <i class="far fa-circle mr-2"></i>{{letter}}
                                        </template>
                                        <template v-else>
                                            <i class="far fa-dot-circle mr-2"></i>{{letter}}
                                        </template>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                    <div class="row ml-0 mt-3 mb-3 mr-0" v-show="salesman.length > 0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchUser">
                                <option value="" selected>Todos</option>
                                <option v-for="(item, index) in salesman" :value="item.usuario" :key="index">{{item.nome}}: {{item.endereco}}</option>
                            </select>
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" type="text" placeholder="Pesquisar vendedor..." v-model="searchs">
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <div class="btn-group w-100" :class="{'mt-3': platform == 'mobile'}">
                                <button type="button" class="btn btn-block btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span><i class="fas fa-print mr-2"></i>Impressão</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <h6 class="dropdown-header">Relatório</h6>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item" type="button" @click="printReportByManager(1)"><i class="fas fa-print mr-2"></i>Todos</button>
                                    <button class="dropdown-item" type="button" @click="printReportByManager(2)"><i class="fas fa-thumbs-up mr-2"></i>Positivo</button>
                                    <button class="dropdown-item" type="button" @click="printReportByManager(3)"><i class="fas fa-thumbs-down mr-2"></i>Negativo</button>
                                </div>
                            </div>
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-primary btn-block" :class="{'mt-3': platform == 'mobile'}" @click="releases"><i class="fas fa-hands-usd mr-2"></i>Fazer lançamento</button>
                        </div>
                    </div><!-- /row -->
                </template>
                <template v-else>
                <div class="row ml-0 mt-3 mb-3 mr-0" v-show="users.length > 0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="user">
                            <option value="all" :selected="user == 'all'">Todos</option>
                            <option v-for="(item, index) in users" :value="item._id" :key="index">{{item.nome}}: {{item.endereco}}</option>
                        </select>
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                    </div>
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="row ml-0 mt-3 mb-3 mr-0" v-show="salesman.length > 0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchUser">
                            <option value="" selected>Todos</option>
                            <option v-for="(item, index) in salesman" :value="item.usuario" :key="index">{{item.nome}}: {{item.endereco}}</option>
                        </select>
                    </div>
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" type="text" placeholder="Pesquisar vendedor..." v-model="searchs">
                    </div>
                </div><!-- /row -->                    
                </template>
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="users.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Não encontramos nenhum vendedor!</p>
                    </div><!-- /alert-danger -->
                    <div class="card mb-3" v-for="(item, index) in filteredListSalesman" :key="index">
                        <div class="card-header">
                            <div class="row">
                                <div class="text-truncate mt-1" :class="{'col-6': (pipe == 3 || pay_accounts == 'sim'), 'col': pay_accounts == 'nao'}">{{item.nome}}: {{item.endereco}}</div>
                                <div class="col pr-0" v-if="pipe == 3 || pay_accounts == 'sim'">
                                    <template v-if="checkBalance(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.retiradas, item.lancamentos) == 0">
                                        <button class="btn btn-sm btn-block btn-success" disabled>
                                            <template v-if="platform == 'desktop'">
                                                <i class="fas fa-handshake mr-2"></i>Em dia
                                            </template>
                                            <template v-else>
                                                <i class="fas fa-handshake"></i>
                                            </template>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button class="btn btn-sm btn-block btn-secondary" @click="closeBoxSalesman(item.id, (item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo - item.retiradas) + item.lancamentos)" :disabled="can_close_box_disabled">
                                            <template v-if="platform == 'desktop'">
                                                <i class="fas fa-hand-holding-usd mr-2"></i>Prestar contas
                                            </template> 
                                            <template v-else>
                                                <i class="fas fa-hand-holding-usd"></i>
                                            </template>
                                        </button>
                                    </template>
                                </div>
                                <div :class="{'col': (pipe == 3 || pay_accounts == 'sim'), 'col-auto': pay_accounts == 'nao'}">
                                    <div class="btn-group w-100">
                                        <button type="button" class="btn btn-sm btn-block btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span v-if="platform == 'desktop'">Mais</span>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <h6 class="dropdown-header">Relatório</h6>
                                            <div class="dropdown-divider"></div>
                                            <button class="dropdown-item" type="button" @click="printReport(item.id)"><i class="fas fa-print mr-2"></i>Imprimir</button>
                                            <button class="dropdown-item" type="button" @click="savePDF(item.id)"><i class="far fa-file-pdf mr-2"></i>Salvar PDF</button>
                                            <button class="dropdown-item" type="button" @click="saveImage(item.id)"><i class="fas fa-file-image mr-2"></i>Salvar imagem</button>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Apurou</div>
                                <div class="col text-truncate">{{item.total_apuracao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas</div>
                                <div class="col text-truncate">{{item.num_apostas | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Ganhadores</div>
                                <div class="col text-truncate">{{item.num_premios | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas abertas</div>
                                <div class="col text-truncate">{{item.num_apostas_abertas | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Entradas em aberto</div>
                                <div class="col text-truncate">{{item.total_apostas_abertas | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Saldo em prêmios</div>
                                <div class="col text-truncate">{{item.total_premios | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (pré-jogo)' : 'Comissões (Pré-jogo'}}</div>
                                <div class="col text-truncate">{{item.total_comissao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (ao-vivo)' : 'Comissões (AV)'}}</div>
                                <div class="col text-truncate">{{item.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (geral)' : 'Comissões (Geral'}}</div>
                                <div class="col text-truncate">{{item.total_comissao + item.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em lançamentos' : 'S. Lançamentos'}}</div>
                                <div class="col text-truncate">{{item.lancamentos | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em retiradas' : 'S. Retiradas'}}</div>
                                <div class="col text-truncate">{{item.retiradas | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Saldo líquido</div>
                                <div class="col text-truncate" :class="positiveOrNegative((item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo))">{{item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Saldo caixa</div>
                                <div class="col text-truncate">{{checkBalance(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.retiradas, item.lancamentos) | formatCoin}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import {jsPDF} from 'jspdf'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import * as rasterizeHTML from 'rasterizehtml'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            pipe: 0,
            error: '',
            users: [],
            user: 'all',
            date1: today,
            date2: today,
            salesman: [],
            searchs: '',
            pay_accounts: 'nao',
            filter_az_active: '',
            loading: false,
            isLoading: false,
            nothing_found: false,
            has_big_select: true,
            can_close_box_disabled: true,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            alphabetic: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        search() {

            const self = this;
            const url = self.user == 'all' ? `dashboard/cash/salesman/${self.date1}/between/${self.date2}/all` : `dashboard/cash/salesman/${self.date1}/between/${self.date2}/user/${self.user}`;

            if (self.date1 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (self.date2 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {
                
                self.searchs = '';
                self.salesman = [];
                self.isLoading = true;
                self.accountabilityReleased();

                api.get(url).then((response) => {
                    switch (response.data.result) {
                        case 'success':
                            self.pay_accounts = response.data.permits.prestar_contas;
                            self.salesman = Object.keys(response.data.salesman).map((key) => {
                                return response.data.salesman[key];
                            }).sort((a, b) => {
                                return a.nome.localeCompare(b.nome);
                            }).filter((item) => item.total_apuracao > 0);
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Atenção!',
                                'Não encontramos nenhum relatório financeiro!',
                                'warning'
                            )
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            )
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        closeBox() {

            const self = this;

            Swal.fire({
                title: 'Fechamento de caixa',
                html: 'Você deseja mesmo fechar o caixa de todos os vendedores?',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then((result) => {

                if (result.value) {

                    self.isLoading = true;

                    api.put('dashboard/close-box').then((response) => {
                        switch(response.data.result) {
                            case 'success':

                                self.updateSalesman();

                                Swal.fire(
                                    'Parabéns!',
                                    'Todos os caixas foram fechados!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });      
        },
        closeBoxSalesman(id, value) {

            try {

                const self = this;
                const salesman = self.salesman.find((item) => item.id == id);

                if (self.can_close_box_disabled) {
                    return;
                } else if (salesman.num_apostas_abertas > 0) {
                    Swal.fire(
                        'Atenção',
                        `O caixa do vendedor "<b>${salesman.nome}</b>". <br /> Ainda tem (<b>${salesman.num_apostas_abertas}</b>) ${salesman.num_apostas_abertas > 1 ? 'apostas abertas' : 'aposta aberta'}. <hr /> O fechamento de caixa só é possível quando não tem nenhuma aposta aberta! `,
                        'warning'
                    );
                } else {
                    Swal.fire({
                        title: 'Prestar contas',
                        html: `Você deseja mesmo prestar contas <br /> do vendendor "<b>${salesman.nome}</b>" ? <p class="mt-3 pt-3 border-top">Valor da transação: <b>${self.formatCoin(value).replace('-','')}</b> <br />  Data da prestação: <b>${self.formatDate(self.date2)}</b></p><p class="mt-3 pt-3 border-top">Tipo de prestação: <b class="${value < 0 ? 'text-success' : 'text-danger'}">${value < 0 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}</b></p>`,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Sim'
                    }).then((result) => {

                        if (result.value) {

                            self.isLoading = true;

                            api.put(`dashboard/close-box/${id}/${value}/${self.date2}`).then((response) => {
                                switch(response.data.result) {
                                    case 'success':

                                        self.updateSalesmanByID(id, value);

                                        Swal.fire(
                                            'Parabéns!',
                                            `O caixa do vendedor ${name} foi fechado!`,
                                            'success'
                                        );
                                    break;
                                    default:
                                        Swal.fire(
                                            'Falha',
                                            response.data.message,
                                            'error'
                                        );
                                }
                            }).catch((error) => {
                                try {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[error.status]['message'],
                                        'error'
                                    );
                                } catch(e) {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[408]['message'],
                                        'error'
                                    );
                                }
                            }).finally(() => {
                                self.isLoading = false;
                            });
                        }
                    }); 
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha',
                    'Ocorreu um erro, atualize a página!',
                    'error'
                );
            }
        },
        printReport(id) {
            
            const self = this;
            const print_iframe = document.createElement('iframe');
            const date1 = moment(self.date1).format('DD/MM/YYYY');
            const date2 = moment(self.date2).format('DD/MM/YYYY');

            try {

                const salesman = self.salesman.find((item) => item.id == id);

                const html = `
                    <html>
                        <head>
                            <style>body{font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                        </head>
                    <body>
                        <br /> 
                            <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                        <br />
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>
                            <center>${document.location.origin}</center>
                        </h5>
                    </body>
                    </html>
                `;

                print_iframe.width = '0px';
                print_iframe.height = '0px';
                print_iframe.src = 'about:blank';
                print_iframe.style.visibility = 'hidden';

                print_iframe.onload = function() {
                    let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                        print_script_tag.type = "text/javascript";
        
                    let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                        print_script_tag.appendChild(print_script);

                    print_iframe.contentWindow.document.body.innerHTML = html;
                    print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                    print_iframe.contentWindow.Print();
                };
                    
                document.body.appendChild(print_iframe);
                print_iframe.remove();

            } catch(e) {
                Swal.fire(
                    'Falha',
                    'Não foi possível imprimir o relatório!',
                    'error'
                );
            }
        },
        printReportByManager(type) {
            
            let html = ``;
            let self = this;
            let print_iframe = document.createElement('iframe');

            if (self.salesman.length == 0) {
                Swal.fire(
                    'Falha',
                    'Não encontramos nenhum vendedor disponível!',
                    'error'
                );
            } else {

                for (let salesman of self.salesman) {

                    let status = self.positiveOrNegative((salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo));

                    if (type == 1) {
                        html += `
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>`;
                    } 
                    else
                    {
                        if (type == 2 && status == 'text-success') {
                            html += `
                            <table class="table" border="1">
                                <tbody>
                                    <tr>
                                        <td colspan="12" align="center" bgcolor="#ddd">
                                            <strong>
                                                <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                            </strong>
                                            <hr />
                                            <p>${salesman.endereco}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Apurou</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Ganhadores</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas abertas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Entradas em aberto</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em prêmios</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (geral)</td>
                                        <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em lançamentos</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em retiradas</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo líquido</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo caixa</td>
                                        <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                    </tr>
                                </tbody>
                            </table>`;
                        } else if (type == 3 &&  status == 'text-danger') {
                            html += `
                            <table class="table" border="1">
                                <tbody>
                                    <tr>
                                        <td colspan="12" align="center" bgcolor="#ddd">
                                            <strong>
                                                <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                            </strong>
                                            <hr />
                                            <p>${salesman.endereco}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Apurou</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Ganhadores</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas abertas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Entradas em aberto</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em prêmios</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (geral)</td>
                                        <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em lançamentos</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em retiradas</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo líquido</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo caixa</td>
                                        <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                    </tr>
                                </tbody>
                            </table>`;
                        }
                    }
                }

                if (!html) {
                    Swal.fire(
                        'Falha',
                        'Nenhum relatório disponível para a opção escolhida!',
                        'error'
                    );
                } else {
                    print_iframe.width = '0px';
                    print_iframe.height = '0px';
                    print_iframe.src = 'about:blank';
                    print_iframe.style.visibility = 'hidden';

                    print_iframe.onload = function() {
                        let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                            print_script_tag.type = "text/javascript";
            
                        let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                            print_script_tag.appendChild(print_script);

                        print_iframe.contentWindow.document.body.innerHTML = `<html>
                            <head>
                                <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table{margin-bottom:10px}.table td {padding:10px}</style>
                                </head>
                            <body class="content">
                                ${html}
                            </body>
                        </html>`;
                        print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                        print_iframe.contentWindow.Print();
                    };
                        
                    document.body.appendChild(print_iframe);
                    print_iframe.remove();
                }
            }
        },
        savePDF(id) {

            const self = this;
            const date1 = moment(self.date1).format('DD/MM/YYYY');
            const date2 = moment(self.date2).format('DD/MM/YYYY');

            try {

                const doc = new jsPDF("p", "mm", "a4");
                const iframe = document.createElement('iframe');
                const salesman = self.salesman.find((item) => item.id == id);

                                const html = `
                    <html>
                        <head>
                            <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                        </head>
                    <body class="content">
                        <br /> 
                            <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                        <br />
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h5>
                            <center>${document.location.origin}</center>
                        </h5>
                    </body>
                    </html>
                `;

                self.isLoading = true;

                iframe.style.width = '100%';
                iframe.style.height = '100%';
                iframe.src = 'about:blank';
                iframe.style.visibility = 'hidden';         

                document.body.appendChild(iframe);

                iframe.contentWindow.document.write(html);

                setTimeout(() => {

                    let canvas = document.createElement('canvas');
                    let ticket = iframe.contentWindow.document.querySelector('.content');

                    canvas.width = ticket.scrollWidth;
                    canvas.height = ticket.scrollHeight;

                    rasterizeHTML.drawHTML(html, canvas).then(() => {
                        self.isLoading = false;
                        doc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, 210, 0);
                        doc.save(`${Math.floor(Math.random() * 1000000)}.pdf`);
                        iframe.remove();
                    });
                }, 100);
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha',
                    'Não foi possível gerar o PDF do relatório!',
                    'error'
                );
            }
        },
        saveImage(id) {

            const self = this;
            const date1 = moment(this.date1).format('DD/MM/YYYY');
            const date2 = moment(this.date2).format('DD/MM/YYYY');

            try {

                const link = document.createElement('a');
                const iframe = document.createElement('iframe');
                const salesman = self.salesman.find((item) => item.id == id);

                const html = `
                    <html>
                        <head>
                            <meta charset="utf-8">
                            <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                        </head>
                    <body class="content">
                        <br /> 
                            <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                        <br />
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h5>
                            <center>${document.location.origin}</center>
                        </h5>
                    </body>
                    </html>
                `;

                self.isLoading = true;

                iframe.style.width = '600px';
                iframe.style.height = '100%';
                iframe.src = 'about:blank';
                iframe.style.visibility = 'hidden';         

                document.body.appendChild(iframe);

                iframe.contentWindow.document.write(html);

                setTimeout(() => {

                    let canvas = document.createElement('canvas');
                    let ticket = iframe.contentWindow.document.querySelector('.content');

                    canvas.width = ticket.scrollWidth + 20;
                    canvas.height = ticket.scrollHeight + 20;

                    rasterizeHTML.drawHTML(html, canvas).then(() => {
                        self.isLoading = false;
                        link.download = Math.floor(Math.random() * 1000000) + '.jpeg';
                        link.href = canvas.toDataURL('image/jpeg');
                        link.click();
                        iframe.remove();
                    });
                }, 100);
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha',
                    'Não foi possível gerar a imagem do relatório!',
                    'error'
                );
            }
        },
        async releases() {

            const self = this;
            const datetime = moment().tz('America/Recife').format('YYYY-MM-DD[T]HH:mm');

            const { value: formValues } = await Swal.fire({
                title: 'Novo lançamento',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Lançar',
                html: `
                    <label class="d-block text-left mb-0" for="swal-input1">Vendedor</label>
                    <select id="swal-input1" class="swal2-input w-100">
                        ${self.salesman.map((user) => {
                            return `<option value="${user.id}">${user.nome}: ${user.endereco}</option>`
                        })}
                    </select>

                    <label class="d-block text-left mb-0" for="swal-input2">Tipo de lançamento</label>
                    <select id="swal-input2" class="swal2-input w-100">
                        <option value="1">Enviar dinheiro</option>
                        <option value="2">Retirar dinheiro</option>
                    </select>

                    <label class="d-block text-left mb-0" for="swal-input3">Valor do lançamento</label>
                    <input id="swal-input3" class="swal2-input" type="text" placeholder="Ex: 100">

                    <label class="d-block text-left mb-0" for="swal-input4">Descrição do lançamento</label>
                    <input id="swal-input4" class="swal2-input" type="text" placeholder="Ex: Pagamento da premiação">

                    <label class="d-block text-left mb-0" for="swal-input5">Horário do lançamento</label>
                    <input id="swal-input5" class="swal2-input" value="${datetime}" type="datetime-local">
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        id: document.getElementById('swal-input1').value,
                        tipo: document.getElementById('swal-input2').value,
                        valor: document.getElementById('swal-input3').value,
                        descricao: document.getElementById('swal-input4').value,
                        horario: document.getElementById('swal-input5').value,
                    }
                },
                onOpen: () => {
                    document.getElementById("swal-input3").addEventListener("input", (e) => {

                        let value = e.target.value;

                        value = value.replace(/\D/g,"");  
                        value = value.replace(/[0-9]{12}/," ");  
                        value = value.replace(/(\d{1})(\d{8})$/,"$1$2");
                        value = value.replace(/(\d{1})(\d{5})$/,"$1$2");  
                        value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
                        
                        return e.target.value = value;
                    });
                }
            });

            if (!formValues) {
                return false;
            } else if (formValues.id == '' || formValues.tipo == '') {
                window.location.reload();
            } else if (formValues.valor == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor do lançamento!',
                    'warning'
                );
            } else if (formValues.descricao == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a descrição do lançamento!',
                    'warning'
                );
            } else if (formValues.horario == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o horário do lançamento!'
                );
            } else {

                const nome = self.salesman.find((item) => item.id == formValues.id).nome;

                Swal.fire({
                    title: 'Confirmar lançamento',
                    html: `Você deseja mesmo enviar um lançamento para o vendendor "<b>${nome}</b>" ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {
                        
                        self.isLoading = true;

                        api.post('dashboard/releases', {
                            form: formValues
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.updateSalesmanReleasesByID(formValues.id, response.data.retiradas, response.data.lancamentos);

                                    Swal.fire(
                                        'Parabéns!',
                                        'O lançamento foi realizado com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        updateSalesman() {
            for (let index in this.salesman) {
                this.salesman[index].caixa = 0;
                this.salesman[index].retiradas = 0;
                this.salesman[index].lancamentos = 0;
            }
        },
        updateSalesmanByID(id, value) {

            const new_value = value.toString().replace(/[^0-9/.]+/g, '');
            const index = this.salesman.findIndex((item) => item.id == id);

            if (index != -1) {

                const salesman = this.salesman[index];

                if (value < 0) {
                    salesman.lancamentos += parseFloat(new_value);
                } else {
                    salesman.retiradas += parseFloat(new_value);
                }
            }
        },
        updateSalesmanReleasesByID(id, retiradas, lancamentos) {

            const index = this.salesman.findIndex((item) => item.id == id);

            if (index != -1) {
                this.salesman[index].retiradas = retiradas;
                this.salesman[index].lancamentos = lancamentos;
            }
        },
        searchUser(event) {
            this.searchs = event.target.value;
        },
        checkValue(value) {
            return value.toString() == '-0' ? 0 : value;
        },
        positiveOrNegative(value) {
            return value < 0 ? 'text-danger' : value == 0 ? 'text-black' : 'text-success';
        },
        checkBalance(total_apuracao, total_premios, total_comissao, total_comissao_ao_vivo, retiradas, lancamentos) {
            return parseFloat(((parseFloat(total_apuracao) - parseFloat(total_premios) - parseFloat(total_comissao) - parseFloat(total_comissao_ao_vivo)) + parseFloat(lancamentos)) - parseFloat(retiradas));
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        accountabilityReleased() {

            var date = moment(this.date1).add(6, 'days').format('YYYY-MM-DD');

            if (date == this.date2) {
                this.can_close_box_disabled = false;
            } else {
                this.can_close_box_disabled = true;
            }            
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        filterAz(filter) {

            const select = document.querySelector('#virtual-select');

            if (filter == 'all') {

                this.filter_az_active = '';

                const options = this.users.map((item) => {
                    return {
                        label: item.nome,
                        value: item._id,
                        description: item.endereco
                    }
                });

                select.setOptions(options);
            } 
            else 
            {
                this.filter_az_active = filter;
                const users = this.users.filter((item) => this.removeAccents(item.nome).toLowerCase().substr(0, 1) == filter);

                if (users.length == 0) {
                    Swal.fire(
                        'Atenção!',
                        `Não encontramos nenhum vendedor com a letra inicial <strong class="text-uppercase">${filter}</strong>.`,
                        'warning'
                    );
                } else {

                    const options = users.map((item) => {
                        return {
                            label: item.nome,
                            value: item._id,
                            description: item.endereco
                        }
                    });

                    select.setOptions(options);
                }
            }
        }
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        }
    },
    watch: {
        users(items) {
            
            var total_items = items.length;

            if (total_items) {
                window.VirtualSelect.init({
                    ele: '#virtual-select',
                    search: true,
                    placeholder: 'Selecione um vendedor',
                    noOptionsText: 'Nenhuma opção disponível',
                    noSearchResultsText: 'Nenhum resultado encontrado',
                    searchPlaceholderText: 'Buscar vendedor...',
                    hasOptionDescription: true,
                    options: this.users.map((item) => {
                        return {
                            label: item.nome,
                            value: item._id,
                            description: item.endereco
                        }
                    })
                });
            }
        }
    },
    computed: {
        filteredListSalesman() {
            return this.salesman.filter((item) => {
                return item.nome.toLowerCase().trim().includes(this.searchs.toLowerCase().trim()) || item.usuario.toLowerCase().trim().includes(this.searchs.toLowerCase().trim())
            });
        }
    },
    beforeMount() {
        try {
            
            const {pipe} = JSON.parse(window.localStorage.getItem('auth'));

            this.pipe = pipe;
        } catch(e) {
            this.pipe = 0;
        }
    },
    mounted() {

        const self = this;

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                if (this.value) {
                    self.user = this.value;
                } else {
                    self.user = 'all';
                }
            });
        }
    },
    created() {

        const self = this;

        self.loading = true;
        self.nothing_found = false;

        api.get('dashboard/type/users/1').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.users = response.data.users.sort((a, b) => {
                        return this.removeAccents(a.nome).localeCompare(this.removeAccents(b.nome), 'fr', {
                            ignorePunctuation: true
                        });
                    });
                break;
                case 'nothing_found':
                    self.nothing_found = true;
                break;
                default:
                    self.error = 'Ocorreu um erro, tente novamente!';
                break;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.col.pl-0 #virtual-select {
    max-width: 100%!important;
}
</style>